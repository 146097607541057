exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-service-jsx": () => import("./../../../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-standalone-about-jsx": () => import("./../../../src/templates/standalone/about.jsx" /* webpackChunkName: "component---src-templates-standalone-about-jsx" */),
  "component---src-templates-standalone-commercial-jsx": () => import("./../../../src/templates/standalone/commercial.jsx" /* webpackChunkName: "component---src-templates-standalone-commercial-jsx" */),
  "component---src-templates-standalone-contact-jsx": () => import("./../../../src/templates/standalone/contact.jsx" /* webpackChunkName: "component---src-templates-standalone-contact-jsx" */),
  "component---src-templates-standalone-portfolio-jsx": () => import("./../../../src/templates/standalone/portfolio.jsx" /* webpackChunkName: "component---src-templates-standalone-portfolio-jsx" */),
  "component---src-templates-standalone-whoweserve-jsx": () => import("./../../../src/templates/standalone/whoweserve.jsx" /* webpackChunkName: "component---src-templates-standalone-whoweserve-jsx" */),
  "component---src-templates-top-index-jsx": () => import("./../../../src/templates/top-index.jsx" /* webpackChunkName: "component---src-templates-top-index-jsx" */)
}

